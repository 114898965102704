export const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

export const isHealthAssuredLocale = (locale: string): boolean => locale === "en";

export const ukLocale = "dac1f6b0-3ee2-4e07-8bcc-61dbc5060e7b";

export const auLocale = "e89b9cc2-cee4-4829-9831-433406e0b792";

export const localeCodeIds = {
  en: ukLocale,
  en_AU: auLocale,
};

export const LocaleIdToCodeMap: { [key: string]: string } = {
  [ukLocale]: "en",
  [auLocale]: "en_AU",
};

export const isAU = (localeId: string) => localeId === localeCodeIds["en_AU"];

export const AdminDomainLocaleCode: Record<string, "en" | "en_AU"> = {
  "backofficewisdom.healthassured.org": "en",
  "admin.wisdomwellbeing.com": "en_AU",
};
